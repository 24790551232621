import { IconTwitter } from '@my/icon/src/IconTwitter'
import { Square } from '@my/ui'
import { useMyDreamBoyMode } from 'app/utils/hooks/useMyDreamBoyMode'
import { Link } from 'solito/link'

export const TwitterLinkUrl = 'https://twitter.com/mydreamboyPr'

export const TwitterLink = () => {
  const isMyDreamBoy = useMyDreamBoyMode()
  return (
    isMyDreamBoy && (
      <Link href={TwitterLinkUrl} target="_blank">
        <Square size={44} br="$1" hoverStyle={{ bg: '$gray5' }}>
          <IconTwitter size="$1" />
        </Square>
      </Link>
    )
  )
}
