import { ColorTokens } from '@my/ui/types'
import { themed } from '@tamagui/helpers-icon'
import { memo } from 'react'
import { Circle, G, Path, Svg } from 'react-native-svg'
// this icon is from simple-icons, generated by `yarn tamagui add icon`
const PoseLogo = (props: any) => {
  const { size, color, ...rest } = props
  return (
    <Svg
      color={color as ColorTokens | undefined}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      {...rest}
    >
      <G fill="none" stroke="currentColor" strokeWidth={4}>
        <Path
          strokeLinecap="round"
          d="M17 22.458c-4.057 1.274-7 5.065-7 9.542 0 5.523 4.477 10 10 10a9.985 9.985 0 0 0 8.662-5"
        />
        <Path strokeLinecap="round" strokeLinejoin="round" d="m17 18 1.5-2H30l-7 14h14v14" />
        <Circle cx={34} cy={8} r={4} />
      </G>
    </Svg>
  )
}
const IconPose = memo(themed(PoseLogo))
export { IconPose }
