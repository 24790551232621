import { Paragraph, XStack, XStackProps } from '@my/ui'
import { Globe } from '@tamagui/lucide-icons'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

export const LanguageSwitcher = (props: XStackProps) => {
  const router = useRouter()

  const onSwitch = () => {
    const locale = router.locale === 'en' ? 'zh' : 'en'
    Cookies.set('NEXT_LOCALE', locale, { expires: 31536000000 })
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale })
  }

  return (
    <XStack {...props} ai="center" gap="$1" cursor="pointer" onPress={() => onSwitch()}>
      <LangText text="文" active={router.locale === 'zh'} />
      <Globe size={16} />
      <LangText text="A" active={router.locale === 'en'} />
    </XStack>
  )
}

function LangText({ text, active }: { text: string; active: boolean }) {
  return (
    <Paragraph size={active ? '$3' : '$1'} fow="700" color={active ? '$color' : '$gray9'} mt="$-1">
      {text}
    </Paragraph>
  )
}
