import { IconDiscord } from '@my/icon/src/IconDiscord'
import { Square } from '@my/ui'
import { useMyDreamBoyMode } from 'app/utils/hooks/useMyDreamBoyMode'
import { Link } from 'solito/link'

export const DiscordLinkUrl = 'https://discord.gg/6DfuMbt3Wz'

export const DiscordLink = () => {
  const isMyDreamBoy = useMyDreamBoyMode()
  return (
    isMyDreamBoy && (
      <Link href={DiscordLinkUrl} target="_blank">
        <Square size={44} br="$1" hoverStyle={{ bg: '$gray5' }}>
          <IconDiscord size="$1.5" />
        </Square>
      </Link>
    )
  )
}
