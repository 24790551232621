import {
  ClientEmailLink,
  Footer,
  Image,
  Paragraph,
  Separator,
  SizableText,
  XStack,
  YStack,
} from '@my/ui'
import { DiscordLink } from 'app/features/socialMedia/DiscordLink/DiscordLink'
import { FacebookLink } from 'app/features/socialMedia/FacebookLink/FacebookLink'
import { TwitterLink } from 'app/features/socialMedia/TwitterLink/TwitterLink'
import { Link } from 'solito/link'

import { LanguageSwitcher } from '../LandingScreen/components/LanguageSwitcher/LanguageSwitcher'

export const FooterBar = ({
  showSocialLink = true,
  showAffiliate = true,
  showContract = false,
  showRefund = false,
}: {
  showSocialLink?: boolean
  showAffiliate?: boolean
  showContract?: boolean
  showRefund?: boolean
}) => {
  return (
    <Footer mt="$16" gap="$5">
      <Separator />
      <XStack jc="space-between" ai="flex-start" $sm={{ flexDirection: 'column', gap: '$5' }}>
        <YStack gap="$5" $sm={{ w: '100%' }}>
          <XStack $sm={{ w: '100%', jc: 'space-between', ai: 'center' }}>
            <XStack ai="center" gap="$3">
              <Image source={{ uri: '/favicon.svg' }} w={24} h={24} />
              <SizableText color="$gray11">© 2024</SizableText>
            </XStack>
            <LanguageSwitcher $gtSm={{ display: 'none' }} />
          </XStack>
          {showSocialLink && (
            <XStack $gtSm={{ display: 'none' }} gap="$2" opacity={0.7}>
              <DiscordLink />
              <TwitterLink />
              <FacebookLink />
            </XStack>
          )}
          <XStack gap="$2" ai="center">
            <Paragraph color="$gray9">Email:</Paragraph>
            <ClientEmailLink />
          </XStack>
        </YStack>
        <YStack gap="$3">
          {showAffiliate && (
            <Link href="/affiliate">
              <SizableText color="$gray9">Affiliate</SizableText>
            </Link>
          )}
          <Link href="/about">
            <SizableText color="$gray9">About</SizableText>
          </Link>
          <Link href="/privacy-policy">
            <SizableText color="$gray9">Privacy Policy</SizableText>
          </Link>
          <Link href="/terms-of-service">
            <SizableText color="$gray9">Terms of Service</SizableText>
          </Link>
          {showContract && (
            <Link href="/contract">
              <SizableText color="$gray9">Specified Commercial Transaction Act</SizableText>
            </Link>
          )}
          {showRefund && (
            <Link href="/refund">
              <SizableText color="$gray9">Refund Policy</SizableText>
            </Link>
          )}
        </YStack>
      </XStack>
    </Footer>
  )
}
