import { Button } from '@my/shadcn-ui'
import { H4, SizableText, XStack, YStack } from '@my/ui'
import { Lock } from '@tamagui/lucide-icons'
import { FooterBar } from 'app/features/landing/FooterBar/Footer'
import { TagScreen } from 'components/landing/TagScreen/TagScreen'
import Link from 'next/link'

import blur from '/public/blur.png'
import example from '/public/example.png'
import result from '/public/result.png'

import { useRef } from 'react'

const Page = () => {
  const ref = useRef<HTMLVideoElement>(null)

  return (
    <>
      <HeaderBar />
      <div className="flex flex-col items-center pt-16 px-6">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-8 p-8 max-w-6xl w-full">
          <div className="flex flex-col space-y-4 lg:w-1/2 w-full z-10">
            <h1 className="text-5xl font-bold">Professional AI Male Images in minutes.</h1>
            <p className="text-gray-200 text-lg">
              Design the man of your fanstasy today.
              <br /> Our cutting-edge AI Generator offers a seamless way to create highly realistic
              AI-generated art and images for personalized content creation.
            </p>
            <div className="flex flex-col space-y-2">
              <Link href="/join-waitlist">
                <Button className="w-full lg:w-1/2">Try Now!</Button>
              </Link>
              <p className="text-sm text-gray-300 italic">
                Trusted by professionals worldwide. Quick and efficient.
              </p>
            </div>
            {/* <div className="mt-4 text-gray-200">
              <span>Already a member? </span>
              <Link className="text-blue-600 hover:underline" href="/sign-in">
                Sign In
              </Link>
            </div> */}
          </div>
          <div className="lg:w-1/2 w-full h-90  mt-8 lg:mt-0 opacity-40 absolute top-0 z-0">
            <img
              src="https://api.mydreamboy.com/storage/v1/object/public/static/shooting.webp"
              alt="AI Headshot Illustration"
              className="rounded-lg object-cover w-full h-full"
            />
          </div>
        </div>
        <XStack maw={920} my="$8">
          <video
            ref={ref}
            autoPlay
            controls
            loop
            controlsList="nodownload noremoteplayback noplaybackrate"
            preload="auto"
            disablePictureInPicture
            disableRemotePlayback
            width="100%"
            muted
            // eslint-disable-next-line react/no-unknown-property
            webkit-playsinline="true"
            // eslint-disable-next-line react/no-unknown-property
            x5-playsinline="true"
            playsInline
            onCanPlay={() => {
              ref.current?.play()
            }}
          >
            <source
              src="https://api.mydreamboy.com/storage/v1/object/public/static/moggai-demo.mp4"
              type="video/mp4"
            />
          </video>
        </XStack>
        <TagScreen />
        <ExplainerSection />
        <PricingPlans />
        <PrivateScreen />
        <NewsLetter />
        <div className=" flex flex-col w-full px-12 py-4">
          <FooterBar showSocialLink={false} showAffiliate={false} showContract={false} showRefund />
        </div>
      </div>
    </>
  )
}

function ExplainerSection() {
  return (
    <div className="mt-22 max-w-7xl mx-auto" id="about">
      <div className="w-full max-w-6xl mt-16 p-8  rounded-lg space-y-8 z-10">
        <h2 className="text-3xl font-bold text-center mb-8">How It Works</h2>

        {/* Step 1: Upload your images */}
        <div className="space-y-4 items-center flex-col flex">
          <div className="flex items-center justify-center space-x-4">
            <div className="text-3xl font-bold text-blue-600 bg-white border-2 border-blue-600 rounded-full w-10 h-10 flex items-center justify-center">
              1
            </div>
            <h3 className="text-2xl font-semibold">Choose the model you like</h3>
          </div>
          <p className="text-sm text-gray-200 text-center">
            You can choose your favorite from millions of models!
          </p>
          <img
            src={example.src}
            alt="AI Headshot example"
            className="rounded-lg object-cover w-full md:w-3/4 lg:w-1/2 mx-auto"
          />
        </div>

        {/* Step 2: Train your model */}
        <div className="space-y-4 items-center flex-col flex">
          <div className="flex items-center justify-center space-x-4">
            <div className="text-3xl font-bold text-blue-600 bg-white border-2 border-blue-600 rounded-full w-10 h-10 flex items-center justify-center">
              2
            </div>
            <h3 className="text-2xl font-semibold">Our AI gets to work</h3>
          </div>
          <p className="text-sm text-gray-200 text-center">
            The AI magic takes ~2 minutes. You'll get images when its ready!
          </p>
          <img
            src={blur.src}
            alt="AI Headshot blur"
            className="rounded-lg object-cover w-full md:w-3/4 lg:w-1/2 mx-auto"
          />
        </div>

        {/* Step 3: Generate images */}
        <div className="space-y-4 items-center flex-col flex">
          <div className="flex items-center justify-center space-x-4">
            <div className="text-3xl font-bold text-blue-600 bg-white border-2 border-blue-600 rounded-full w-10 h-10 flex items-center justify-center">
              3
            </div>
            <h3 className="text-2xl font-semibold">Get Your Images</h3>
          </div>
          <p className="text-sm text-gray-200 text-center">
            Once your model is trained, we'll give you amazing images!
          </p>
          <img
            src={result.src}
            alt="AI Headshot result"
            className="rounded-lg object-cover w-full md:w-3/4 lg:w-1/2 mx-auto"
          />
        </div>
      </div>
    </div>
  )
}

function HeaderBar() {
  return (
    <header className="py-4 px-4 sm:px-10 z-50 min-h-[70px] relative">
      <div className="lg:flex lg:items-center gap-x-2 relative">
        <div className="flex items-center shrink-0">
          <a href="/">
            <img src="/favicon.svg" alt="logo" className="w-14" />
          </a>
          <button id="toggle" className="lg:hidden ml-auto">
            <svg
              className="w-7 h-7"
              fill="#fff"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          id="collapseMenu"
          className="lg:!flex lg:items-center w-full lg:ml-14 max-lg:hidden max-lg:bg-black gap-6 max-lg:fixed max-lg:w-1/2 max-lg:min-w-[250px] max-lg:top-0 max-lg:left-0 max-lg:p-4 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto"
        >
          <ul className="lg:flex lg:space-x-6 max-lg:space-y-6 max-lg:w-full">
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <Link
                href="#features"
                className="lg:hover:text-blue-600 block transition-all"
                scroll={false}
              >
                Features
              </Link>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <Link href="#about" className="lg:hover:text-blue-600 block transition-all" scroll>
                About
              </Link>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <Link href="#pricing" className="lg:hover:text-blue-600 block transition-all" scroll>
                Pricing
              </Link>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <Link href="/explore" className="lg:hover:text-blue-600 block transition-all" scroll>
                Explore
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

function NewsLetter() {
  return (
    <div className="bg-[#111] w-full sm:px-10 py-12 mt-32">
      <div className="lg:max-w-[50%] mx-auto text-center">
        <h2 className="md:text-4xl text-3xl font-bold md:!leading-[50px] mb-6">Newsletter</h2>
        <p className="text-gray-400">
          Subscribe to our newsletter and stay up to date with the latest news, updates, and
          exclusive offers. Get valuable insights. Join our community today!
        </p>
        <div className="bg-[#444] flex px-2 py-1 rounded-md text-left mt-10">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full outline-none bg-transparent pl-2"
          />
          <button
            type="button"
            className="px-6 py-3 rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all ml-auto"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

function PricingPlans() {
  return (
    <div className="mt-32 max-w-7xl mx-auto" id="pricing">
      <div className="text-center">
        <h2 className="md:text-4xl text-3xl font-bold md:!leading-[50px] mb-6">Pricing Plans</h2>
        <p className="text-gray-400">Change your plant according your needs</p>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 mt-16 max-md:max-w-md max-md:mx-auto">
        <div className="hover:outline outline-blue-600 rounded relative overflow-hidden transition-all p-6">
          <div className="text-left">
            <h4 className="text-xl mb-4">Starter</h4>
            <h3 className="text-4xl">$4.99</h3>
            <p className="mt-4 text-gray-400">
              Ideal for individuals who wanna try some basic features and explore platform magic.
            </p>
          </div>
          <div className="mt-8">
            <ul className="space-y-4">
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                100 Image generations
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                100 Credits
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Monthly 10 Credits Free
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Customer Support
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                10GB Cloud Storage
              </li>
            </ul>
          </div>
          <SignUpWrapper>
            <button
              type="button"
              className="w-full px-6 py-3.5 rounded-md text-white bg-blue-700 hover:bg-blue-800 transition-all mt-10"
            >
              Get started today
            </button>
          </SignUpWrapper>
        </div>
        <div className="hover:outline outline-blue-600 rounded relative overflow-hidden transition-all p-6">
          <div className="text-left">
            <h4 className="text-xl mb-4">Hobby</h4>
            <h3 className="text-4xl">$9.99</h3>
            <p className="mt-4 text-gray-400">
              Ideal for individuals who need quick access to basic features and more image
              generation.
            </p>
          </div>
          <div className="mt-8">
            <ul className="space-y-4">
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                200 Image generations
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                200 Credits
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Monthly 20 Credits Free
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Customer Support
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                50GB Cloud Storage
              </li>
            </ul>
          </div>
          <SignUpWrapper>
            <button
              type="button"
              className="w-full px-6 py-3.5 rounded-md text-white bg-blue-700 hover:bg-blue-800 transition-all mt-10"
            >
              Get started today
            </button>
          </SignUpWrapper>
        </div>
        <div className="hover:outline outline-blue-600 rounded relative overflow-hidden transition-all p-6">
          <div className="text-left">
            <h4 className="text-xl mb-4">Professional</h4>
            <h3 className="text-4xl">$19.99</h3>
            <p className="mt-4 text-gray-400">
              Ideal for individuals who who need advanced features and tools for client work.
            </p>
          </div>
          <div className="mt-8">
            <ul className="space-y-4">
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                500 Image generations
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                500 Credits
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Monthly 100 Credits Free
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Customer Support
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                500GB Cloud Storage
              </li>
            </ul>
          </div>
          <SignUpWrapper>
            <button
              type="button"
              className="w-full px-6 py-3.5 rounded-md text-white bg-blue-700 hover:bg-blue-800 transition-all mt-10"
            >
              Get started today
            </button>
          </SignUpWrapper>
        </div>
        <div className="hover:outline outline-blue-600 rounded relative overflow-hidden transition-all p-6">
          <div className="text-left">
            <h4 className="text-xl mb-4">Business</h4>
            <h3 className="text-4xl">$29.99</h3>
            <p className="mt-4 text-gray-400">
              Ideal for businesses who need personalized services and security for large teams.
            </p>
          </div>
          <div className="mt-8">
            <ul className="space-y-4">
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                1000 Image generations
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                1000 Credits
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Monthly 200 Credits Free
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                Customer Support
              </li>
              <li className="flex items-center text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  className="mr-4 bg-gray-200 fill-[#333] rounded-full p-[3px]"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                    data-original="#000000"
                  />
                </svg>
                1500GB Cloud Storage
              </li>
            </ul>
          </div>
          <SignUpWrapper>
            <button
              type="button"
              className="w-full px-6 py-3.5 rounded-md text-white bg-blue-700 hover:bg-blue-800 transition-all mt-10"
            >
              Get started today
            </button>
          </SignUpWrapper>
        </div>
      </div>
    </div>
  )
}

function SignUpWrapper({ children }: { children: React.ReactNode }) {
  return <Link href="/join-waitlist">{children}</Link>
}

function PrivateScreen() {
  return (
    <YStack ai="center" mt="$17" gap="$8" $sm={{ mt: '$12' }}>
      <XStack ai="center" gap="$4">
        <Lock size="$4" />
        <H4 size="$10" ai="center" ta="center" $sm={{ size: '$9' }}>
          Protecting your creations
        </H4>
      </XStack>
      <SizableText w={800} ta="center" size="$5" color="$gray9" $sm={{ w: '100%', size: '$4' }}>
        When creating images and customizing models, you can choose to keep the image or model
        private or public. Choosing Public allows others to view the image or model on the home page
        or in your personal center; choosing Private allows only you to see the creation.
      </SizableText>
    </YStack>
  )
}

export default Page
