import { useDidFinishSSR } from '@tamagui/core'
import Link from 'next/link'
import { SizableText, SizableTextProps } from 'tamagui'

export type ClientEmailLinkProps = {
  prefix?: string
} & SizableTextProps

export const ClientEmailLink = ({ prefix = 'support', ...props }: ClientEmailLinkProps) => {
  const isClientSide = useDidFinishSSR()
  if (!isClientSide) return null

  const email = `${prefix}@${location.hostname.replace('www.', '')}`

  return (
    <Link href={`mailto:${email}`}>
      <SizableText color="$blue9" {...props}>
        {email}
      </SizableText>
    </Link>
  )
}
