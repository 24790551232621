import { Square } from '@my/ui'
import { Facebook } from '@tamagui/lucide-icons'
import { useMyDreamBoyMode } from 'app/utils/hooks/useMyDreamBoyMode'
import { Link } from 'solito/link'

export const FacebookLinkUrl = 'https://www.facebook.com/mydreamboydotcom'

export const FacebookLink = () => {
  const isMyDreamBoy = useMyDreamBoyMode()
  return (
    isMyDreamBoy && (
      <Link href={FacebookLinkUrl} target="_blank">
        <Square size={44} br="$1" hoverStyle={{ bg: '$gray5' }}>
          <Facebook size="$1.5" />
        </Square>
      </Link>
    )
  )
}
