import { H4, SizableText, XStack, YStack } from '@my/ui'
import { MoreHorizontal } from '@tamagui/lucide-icons'

import { IconActivity } from './components/IconActivity'
import { IconBody } from './components/IconBody'
import { IconPose } from './components/IconPose'
import { IconScene } from './components/IconScene'
import { IconSize } from './components/IconSize'

const tagsDesc = [
  {
    title: 'Different compositions',
    desc: 'You can generate headshots, upper body, full body and many other composition types separately',
  },
  {
    title: 'Customizable body elements',
    desc: 'You can customize hairstyles, clothes, accessories, facial expressions, and a variety of other detailed elements',
  },
  {
    title: 'various postures',
    desc: 'You can keep your model in a variety of poses such as standing, sitting, arms up, arms crossed, etc',
  },
  {
    title: 'Rich Scenery',
    desc: 'You can have the model stay indoors (store, gym, bedroom...) or outdoors (road, beach, city...) and so on',
  },
  {
    title: 'Fun activities',
    desc: 'You can customize a variety of fun activities like basketball, skateboarding, drinking coffee, etc.',
  },
  {
    title: 'Continuously updated content',
    desc: 'We are constantly updating our content to make your models come more and more to life!',
  },
]

const icons = [IconSize, IconBody, IconPose, IconScene, IconActivity, MoreHorizontal]

export const TagScreen = () => {
  const contents = tagsDesc?.map((item, index) => ({
    ...item,
    icon: icons[index]!,
  }))
  return (
    <YStack ai="center" $sm={{ mt: '$10' }}>
      <YStack position="relative" top={-150} id="features" />
      <H4 size="$10" $sm={{ size: '$9' }} ta="center">
        Create Images As You Want
      </H4>
      <SizableText theme="alt1" mt="$3.5" textAlign="center">
        We use state-of-the-art Artificial Intelligence technology to provide a huge variety of
        image tags that can be customized to the highest level of image detail!
      </SizableText>
      <XStack fw="wrap" maxWidth={900} jc="space-around" rowGap="$5" mt="$8">
        {contents?.map((item, index) => (
          <YStack
            key={index}
            w="30%"
            p="$6"
            gap="$3"
            borderWidth="$0.5"
            borderColor="$gray3"
            borderRadius="$4"
            backgroundColor="$gray1"
            $sm={{ w: '45%', p: '$3' }}
          >
            <item.icon size="$3" />
            <SizableText fow="700" $sm={{ size: '$3', fow: '700' }}>
              {item.title}
            </SizableText>
            <SizableText color="$gray11" $sm={{ size: '$3' }}>
              {item.desc}
            </SizableText>
          </YStack>
        ))}
      </XStack>
    </YStack>
  )
}
